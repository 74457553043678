import React from "react"
import { Helmet } from "react-helmet"
import SEO from "../components/seo"
import Topicfutbol from "../components/topicfutbol"
import Notesfutbol from "../components/notesfutbol"
import Mainfutbol from "../components/mainfutbol"

const FutbolPage = () => (
  <>
    <SEO title="Futbol Internacional" />
    <Helmet>
      <meta name="robots" content="noindex,nofollow" />
    </Helmet>
    <div className="grid-container-templatenota">
      <div className="templatenota-banner-section"></div>
      <div>
        <Mainfutbol />
        <Topicfutbol />
        <Notesfutbol />
      </div>
      <div className="templatenota-banner-section"></div>
    </div>
  </>
)

export default FutbolPage
